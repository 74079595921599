import { Component } from 'react';
import {
  Col,
  Collapse,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  TabContent,
} from 'reactstrap';
import { Tab } from '../../components/Tab';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import { Tile } from '../../components/Tile';
import { Tabs } from '../../components/Tabs';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Logo } from '../header/Logo';

interface IProps extends RouteComponentProps {
  filters: any;
  products: any;
}

interface IState {
  activeCategory: string;
  searchInput: string;
  showFilters: boolean;
  activeIndustry: string;
  activeType: string;
  activeStandard: string;
}

class Overview extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeCategory: '',
      searchInput: '',
      showFilters: false,
      activeIndustry:
        new URLSearchParams(props.location?.search)?.get('industry') || '',
      activeType: '',
      activeStandard: '',
    };
  }

  render() {
    const usedIndustries = new Set();
    const usedCategories = new Set();
    this.props.products.products.forEach((product: any) => {
      product.variants.forEach((variant: any) => {
        variant.industries.forEach((industry: any) =>
          usedIndustries.add(industry),
        );
        variant.categories.forEach((category: any) =>
          usedCategories.add(category),
        );
      });
    });

    const filteredProducts = this.props.products.products.filter(
      (product: any) =>
        product.variants.some(
          (variant: any) =>
            (((!product.name || !variant.name) && !this.state.searchInput) ||
              product.name
                ?.toLowerCase()
                .includes(this.state.searchInput.toLowerCase()) ||
              variant.name
                ?.toLowerCase()
                .includes(this.state.searchInput.toLowerCase())) &&
            (((!variant.categories || variant.categories.length == 0) &&
              !this.state.activeCategory) ||
              variant.categories?.some((category: any) =>
                category
                  .toLowerCase()
                  .includes(this.state.activeCategory.toLowerCase()),
              )) &&
            (((!variant.industries || variant.industries.length == 0) &&
              !this.state.activeIndustry) ||
              variant.industries?.some((industry: any) =>
                industry
                  .toLowerCase()
                  .includes(this.state.activeIndustry.toLowerCase()),
              )) &&
            (((!variant.types || variant.types.length == 0) &&
              !this.state.activeType) ||
              variant.types?.some((type: any) =>
                type
                  .toLowerCase()
                  .includes(this.state.activeType.toLowerCase()),
              )) &&
            (((!variant.standards || variant.standards.length == 0) &&
              !this.state.activeStandard) ||
              variant.standards?.some((standard: any) =>
                standard
                  .toLowerCase()
                  .includes(this.state.activeStandard.toLowerCase()),
              )),
        ),
    );

    return (
      <>
        <div className="p-3 p-md-4 bg-secondary pb-4">
          <Logo />
        </div>

        <div className="bg-secondary px-3 px-md-4">
          <h1 className="mb-4">All Products</h1>

          {/* <Tabs>
            <Tab
                active={!this.state.activeCategory}
                onClick={() => {
                  this.setState({activeCategory: ''});
                }}
                label={'All'}
            />
            {this.props.filters.categories.map((category: any) => (
                <Tab
                    active={this.state.activeCategory === category}
                    onClick={() => {
                      this.setState({activeCategory: category});
                    }}
                    label={category}
                    disabled={!usedCategories.has(category)}
                />
            ))}
          </Tabs> */}
        </div>

        <TabContent
          activeTab={this.state.activeCategory}
          className="h-100 mt-4 px-3 px-md-4"
          style={{ overflowX: 'hidden', overflowY: 'auto' }}
        >
          <Row className="align-items-center g-2 mb-2">
            <Col className="col-12 col-md-3">
              <InputGroup>
                <InputGroupText className="text-primary border-end-0">
                  <Label for="search" className="m-0">
                    <FaSearch />
                  </Label>
                </InputGroupText>

                <Input
                  id="search"
                  type="search"
                  placeholder="Search"
                  className="border-start-0"
                  value={this.state.searchInput}
                  onChange={(event) =>
                    this.setState({ searchInput: event.target.value })
                  }
                />
              </InputGroup>
            </Col>

            {/* <Col className="d-flex flex-column flex-md-row justify-content-md-end">
              <div
                  className="align-items-center d-flex"
                  onClick={() =>
                      this.setState({showFilters: !this.state.showFilters})
                  }
                  style={{cursor: 'pointer'}}
              >
                <div className="d-md-none">
                  <span className="me-2">Show Filters</span>
                  {this.state.showFilters ? (
                      <FaChevronUp className="text-primary"/>
                  ) : (
                      <FaChevronDown className="text-primary"/>
                  )}
                </div>
                <span className="d-none d-md-block">Filters</span>
              </div>
              <Collapse isOpen={this.state.showFilters} className="d-md-block">
                <div className="d-flex flex-column flex-md-row">
                  <Input
                      type="select"
                      className="mb-2 mb-md-0 ms-md-2"
                      value={this.state.activeIndustry}
                      onChange={(event) =>
                          this.setState({
                            activeIndustry: event.target.value,
                          })
                      }
                  >
                    <option key={''} value={''} style={{fontStyle: 'italic'}}>
                      Industry
                    </option>
                    {this.props.filters.industries.map((industry: any) => (
                        <option
                            key={industry}
                            value={industry}
                            disabled={!usedIndustries.has(industry)}
                        >
                          {industry}
                        </option>
                    ))}
                  </Input>

                  <Input
                      type="select"
                      className="mb-2 mb-md-0 ms-md-2"
                      value={this.state.activeType}
                      onChange={(event) =>
                          this.setState({
                            activeType: event.target.value,
                          })
                      }
                  >
                    <option key={''} value={''} style={{fontStyle: 'italic'}}>
                      Type of test
                    </option>
                    {this.props.filters.types.map((type: any) => (
                        <option>{type}</option>
                    ))}
                  </Input>

                  <Input
                      type="select"
                      className="mb-2 mb-md-0 ms-md-2"
                      value={this.state.activeStandard}
                      onChange={(event) =>
                          this.setState({
                            activeStandard: event.target.value,
                          })
                      }
                  >
                    <option key={''} value={''} style={{fontStyle: 'italic'}}>
                      Standard
                    </option>
                    {this.props.filters.standards.map((standard: any) => (
                        <option>{standard}</option>
                    ))}
                  </Input>
                </div>
              </Collapse>
            </Col> */}
          </Row>

          <div>
            <h5>
              Products{' '}
              <span style={{ color: '#666666' }}>
                ({filteredProducts.length})
              </span>
            </h5>

            <div className="p-0">
              <Row className="g-2 g-md-3">
                {filteredProducts.map((product: any) => (
                  <Col className="col-12 col-md-3 d-block">
                    <Tile
                      title={product.name}
                      text={product.shortDescription || product.description}
                      imageUrl={product.thumbnailUrl}
                      onClick={() =>
                        this.props.history.push({
                          pathname: `/configurator`,
                          state: { product: product },
                        })
                      }
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </TabContent>
      </>
    );
  }
}

export default withRouter(Overview);
