import { Component } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import cover from './cover.jpg';
import { Logo } from '../header/Logo';

interface IProps {
  onSubmit: (filter: any, products: any) => void;
}

interface IState {
  password: string;
  isLoginInvalid: boolean;
  isLoading: boolean;
}

export class Login extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      password: '',
      isLoginInvalid: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadConfigs();
  }

   loadConfigs = async () =>  {
    this.setState({ isLoading: true });

    try {
      const configPaths = await (
        await fetch(`${process.env.REACT_APP_CONFIGS_ENDPOINT}`, {
          headers: new Headers({
            Authorization:
              'Basic ' + btoa('zwickroell' + ':' + this.state.password),
          }),
        })
      ).json();

      const [filters, products] = await Promise.all([
        (await fetch(`${configPaths.filtersUrl}`)).json(),
        (await fetch(`${configPaths.productsUrl}`)).json(),
      ]);

      this.props.onSubmit(filters, products);
    } catch (event) {
      this.setState({ isLoginInvalid: true });
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <>
        <div className="p-3 p-md-4 pb-4 position-absolute">
          <Logo />
        </div>

        <Form className="row h-100 justify-content-center p-3 p-md-0">
          <Col className="col-5 h-100 bg-secondary d-none d-md-flex align-items-center justify-content-center p-0">
            <img
              src={cover}
              className="h-100 w-100"
              style={{ objectFit: 'cover' }}
            />

            <div
              className="position-absolute"
              style={{ bottom: '20px', left: '20px' }}
            >
              <h2 className="bg-secondary bg-opacity-50">
                Your Partner in <br /> Materials Testing
              </h2>
            </div>
          </Col>

          <Col className="col-md-7 my-md-auto">
            <div
              className="d-flex flex-column h-100 mx-auto"
              style={{ maxWidth: '412px' }}
            >
              <FormGroup className="text-center mb-2 mt-auto">
                <h2>Welcome to ZwickRoell</h2>

                {/*<p style={{ fontSize: '18px' }}>*/}
                {/*  Enter password to access the application*/}
                {/*</p>*/}
              </FormGroup>

              {/*<FormGroup>*/}
              {/*  <Label for="passwordInput">Password</Label>*/}
              {/*  <Input*/}
              {/*    id="passwordInput"*/}
              {/*    className="bg-secondary"*/}
              {/*    type="password"*/}
              {/*    value={this.state.password}*/}
              {/*    onChange={(event) =>*/}
              {/*      this.setState({ password: event.target.value })*/}
              {/*    }*/}
              {/*    invalid={this.state.isLoginInvalid}*/}
              {/*  />*/}
              {/*  <div className="invalid-feedback">*/}
              {/*    Password is incorrect. Check and try again.*/}
              {/*  </div>*/}
              {/*</FormGroup>*/}

              <div className="mt-auto d-md-none" />

              <Button
                color="primary"
                className="w-100 d-flex align-items-center justify-content-center"
                //onClick={this.loadConfigs}
              >
                {'Preparing the configurator...'}
                {this.state.isLoading && <Spinner size="sm" className="ms-2" />}
              </Button>
            </div>
          </Col>
        </Form>
      </>
    );
  }
}
