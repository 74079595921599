import { Component, createRef } from 'react';
import { ToggleButton } from '../welcome/ToggleButton';
import { HorizontalScrollContainer } from '../../components/HorizontalScrollContainer';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { CubeIcon } from './CubeIcon';
import { MeasureIcon } from './MeasureIcon';
import { InfoIcon } from './InfoIcon';
import { IoCloseOutline } from 'react-icons/io5';
import { QRCodeSVG } from 'qrcode.react';
import { Logo } from '../header/Logo';

interface LocationState {
  product: any;
  variant: any;
}

interface IProps extends RouteComponentProps<{}, any, LocationState> {}

interface IState {
  activeVariant: any;
  isARCompatible?: boolean;
  isViewerReady?: boolean;
  isARModalOpen?: boolean;
  isAnnotationsAvailable?: boolean;
  isAnimationsAvailable?: boolean;
  isShowDimensions?: boolean;
  isShowAnnotations?: boolean;
  isShowAnimations?: boolean;
  arPresetId?: any;
}

class Configurator extends Component<IProps, IState> {
  viewer: any;

  constructor(props: IProps) {
    super(props);

    const initialActiveVariant =
      props.location.state.variant || props.location.state.product.variants[0];

    this.state = {
      activeVariant: initialActiveVariant,
      isARModalOpen: false,
      isShowDimensions: false,
      isShowAnnotations: false,
      isShowAnimations: false,
      arPresetId:
        initialActiveVariant.arTrueSizePresetId ||
        initialActiveVariant.arDeskSizePresetId,
    };

    this.viewer = createRef();
  }

  componentDidMount() {
    window.addEventListener(
      'message',
      (event) => {
        if (!this.viewer) {
          return;
        }

        if (event.data.action === 'viewerStarted') {
          this.viewer?.current.contentWindow.postMessage(
            { action: 'isARCompatible' },
            '*',
          );

          this.viewer?.current.contentWindow.postMessage(
            { action: 'getAnnotations' },
            '*',
          );

          this.viewer?.current.contentWindow.postMessage(
            { action: 'getAnimations' },
            '*',
          );

          this.setState({ isViewerReady: true });
        } else if (event.data.actionCalled === 'isARCompatible') {
          this.setState({
            isARCompatible: event.data.isARCompatible,
          });
        } else if (event.data.actionCalled === 'getAnnotations') {
          this.setState({
            isAnnotationsAvailable: event.data.annotations.length > 0,
          });
        } else if (event.data.actionCalled === 'getAnimations') {
          this.setState({
            isAnimationsAvailable: event.data.animations.length > 0,
          });
        }
      },
      false,
    );
  }

  render() {
    // prepare iframe url
    const viewerUrl = new URL(this.state.activeVariant.viewerUrl);
    const searchParams = viewerUrl.searchParams;
    searchParams.set('viewer_api', 'true');
    searchParams.set('controls', 'false');
    searchParams.set('white_label', 'true');
    searchParams.set('autoplay', 'true');

    return (
      <>
        <Row
          className="position-fixed d-flex flex-column flex-md-row"
          style={{ inset: 0 }}
        >
          <Col className="col-12 col-md-8 d-flex flex-column bg-secondary position-relative p-0 flex-grow-1">
            <div className="p-3 p-md-4 bg-secondary pb-4 d-flex align-items-center">
              <span className="me-2 mt-2">{'<'}</span> <Logo href="#overview" />
            </div>

            <iframe
              id="viewer"
              src={viewerUrl.toString()}
              height="100%"
              width="100%"
              ref={this.viewer}
              onLoad={() => {
                this.viewer?.current.contentWindow.postMessage(
                  { action: 'subscribe' },
                  '*',
                );
              }}
            />

            {this.state.isViewerReady && (
              <HorizontalScrollContainer className="justify-content-md-center mb-4 position-absolute bottom-0">
                {(this.state.activeVariant.arTrueSizePresetId ||
                  this.state.activeVariant.arDeskSizePresetId) && (
                  <ToggleButton
                    color="primary"
                    className="configurator-button text-uppercase me-2 d-flex align-items-center"
                    onClick={() => {
                      this.setState({ isARModalOpen: true });
                    }}
                    active={this.state.isARModalOpen}
                  >
                    <CubeIcon />
                    <span className="ms-2">See in your room</span>
                  </ToggleButton>
                )}

                {this.state.activeVariant.dimensionsMaterialIds?.length > 0 && (
                  <ToggleButton
                    color="primary"
                    className="configurator-button text-uppercase me-2 d-flex align-items-center"
                    active={this.state.isShowDimensions}
                    onClick={() => {
                      if (this.state.isShowDimensions) {
                        this.state.activeVariant.dimensionsMaterialIds.forEach(
                          (materialId: string) =>
                            this.viewer?.current.contentWindow.postMessage(
                              {
                                action: 'hideMaterial',
                                materialId: materialId,
                              },
                              '*',
                            ),
                        );
                        this.setState({ isShowDimensions: false });
                      } else {
                        this.state.activeVariant.dimensionsMaterialIds.forEach(
                          (materialId: string) =>
                            this.viewer?.current.contentWindow.postMessage(
                              {
                                action: 'showMaterial',
                                materialId: materialId,
                              },
                              '*',
                            ),
                        );
                        this.setState({ isShowDimensions: true });
                      }
                    }}
                  >
                    <MeasureIcon />
                    <span className="ms-2">Show dimensions</span>
                  </ToggleButton>
                )}

                {this.state.isAnnotationsAvailable && (
                  <ToggleButton
                    color="primary"
                    className="configurator-button text-uppercase d-flex align-items-center"
                    active={this.state.isShowAnnotations}
                    onClick={() => {
                      if (this.state.isShowAnnotations) {
                        this.viewer?.current.contentWindow.postMessage(
                          {
                            action: 'hideAnnotationTriggers',
                          },
                          '*',
                        );

                        this.setState({ isShowAnnotations: false });
                      } else {
                        this.viewer?.current.contentWindow.postMessage(
                          {
                            action: 'showAnnotationTriggers',
                          },
                          '*',
                        );

                        this.setState({ isShowAnnotations: true });
                      }
                    }}
                  >
                    <InfoIcon />
                    <span className="ms-2">Show details</span>
                  </ToggleButton>
                )}

                {this.state.isAnimationsAvailable && (
                  <ToggleButton
                    color="primary"
                    className="configurator-button text-uppercase d-flex align-items-center"
                    active={this.state.isShowAnimations}
                    onClick={() => {
                      if (this.state.isShowAnimations) {
                        this.viewer?.current.contentWindow.postMessage(
                          {
                            action: 'hideAnimationTriggers',
                          },
                          '*',
                        );

                        this.setState({ isShowAnimations: false });
                      } else {
                        this.viewer?.current.contentWindow.postMessage(
                          {
                            action: 'showAnimationTriggers',
                          },
                          '*',
                        );

                        this.setState({ isShowAnimations: true });
                      }
                    }}
                  >
                    <InfoIcon />
                    <span className="ms-2">Show animations</span>
                  </ToggleButton>
                )}
              </HorizontalScrollContainer>
            )}

            {this.state.isARModalOpen && (
              <div
                className="d-flex position-absolute bottom-0 h-100 w-100 justify-content-center align-items-center"
                style={{ zIndex: 999 }}
              >
                <Card
                  className="bg-white p-2 position-relative"
                  style={{ maxWidth: '80%' }}
                >
                  <Button
                    color="none"
                    className="position-absolute"
                    style={{ color: '#666666', top: 0, right: 0 }}
                    onClick={() =>
                      this.setState({
                        isARModalOpen: false,
                      })
                    }
                  >
                    <IoCloseOutline style={{ width: '24px', height: '24px' }} />
                  </Button>

                  <CardBody className="justify-content-center">
                    <Row className="g-2">
                      <Col>
                        {this.state.isARCompatible ? (
                          <h3 className="mb-4 text-center">
                            See the{' '}
                            <span className="text-primary">
                              {this.state.activeVariant.name}
                            </span>{' '}
                            in AR{' '}
                          </h3>
                        ) : (
                          <h3 className="mb-4">
                            Scan the QR-Code with your smartphone to see the{' '}
                            <span className="text-primary">
                              {this.state.activeVariant.name}
                            </span>{' '}
                            in AR
                          </h3>
                        )}

                        <Row className="g-2">
                          {this.state.activeVariant.arTrueSizePresetId && (
                            <Col className="col-12 col-md-6">
                              {this.state.isARCompatible ? (
                                <Button
                                  color="primary"
                                  outline
                                  className="text-uppercase w-100"
                                  onClick={() =>
                                    window.open(
                                      `${
                                        viewerUrl.origin + viewerUrl.pathname
                                      }?preset=${
                                        this.state.activeVariant
                                          .arTrueSizePresetId
                                      }&ar_only=true`,
                                      '_blank',
                                    )
                                  }
                                >
                                  Open in 1:1 (True size)
                                </Button>
                              ) : (
                                <ToggleButton
                                  color="primary"
                                  className="text-uppercase w-100 text-black border-secondary"
                                  active={
                                    this.state.arPresetId ===
                                    this.state.activeVariant.arTrueSizePresetId
                                  }
                                  onClick={() =>
                                    this.setState({
                                      arPresetId:
                                        this.state.activeVariant
                                          .arTrueSizePresetId,
                                    })
                                  }
                                >
                                  1:1 true size
                                </ToggleButton>
                              )}
                            </Col>
                          )}

                          {this.state.activeVariant.arDeskSizePresetId && (
                            <Col className="col-12 col-md-6">
                              {this.state.isARCompatible ? (
                                <Button
                                  color="primary"
                                  outline
                                  className="text-uppercase w-100"
                                  onClick={() =>
                                    window.open(
                                      `${
                                        viewerUrl.origin + viewerUrl.pathname
                                      }?preset=${
                                        this.state.activeVariant
                                          .arDeskSizePresetId
                                      }&ar_only=true`,
                                      '_blank',
                                    )
                                  }
                                >
                                  Open in 1:16 (desk size)
                                </Button>
                              ) : (
                                <ToggleButton
                                  color="primary"
                                  className="text-uppercase w-100 text-black border-secondary"
                                  active={
                                    this.state.arPresetId ===
                                    this.state.activeVariant.arDeskSizePresetId
                                  }
                                  onClick={() =>
                                    this.setState({
                                      arPresetId:
                                        this.state.activeVariant
                                          .arDeskSizePresetId,
                                    })
                                  }
                                >
                                  1:16 desk size
                                </ToggleButton>
                              )}
                            </Col>
                          )}
                        </Row>
                      </Col>

                      {!this.state.isARCompatible && (
                        <Col className="col-12 col-md-6 d-flex flex-column align-items-center">
                          <QRCodeSVG
                            value={`${
                              viewerUrl.origin + viewerUrl.pathname
                            }?preset=${this.state.arPresetId}&ar_only=true`}
                            style={{ border: 'solid 1px' }}
                            className="border-secondary mb-2"
                            includeMargin={true}
                          />

                          <p
                            className="text-muted text-center w-50"
                            style={{ fontSize: '12px' }}
                          >
                            Requires iOS 13+, iPadOS 13+ or Android with ARCore
                            1.9+
                          </p>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </div>
            )}
          </Col>

          <Col
            className="col-12 col-md-4 d-flex flex-column p-3 p-md-4"
            style={{ maxHeight: '100%' }}
          >
            <h2 className="mb-3">{this.props.location.state.product.name}</h2>

            {this.props.location.state.product.variants && (
              <>
                <h6 className="mb-3">Select variant</h6>

                <Row className="d-none d-md-block g-2 g-md-3 overflow-auto mb-3">
                  {this.props.location.state.product.variants.map(
                    (variant: any) => (
                      <Col className="col-12 d-flex justify-content-center">
                        <ToggleButton
                          color="primary"
                          className="text-black text-start border-secondary w-100"
                          active={this.state.activeVariant == variant}
                          onClick={() => {
                            this.setState({
                              activeVariant: variant,
                              isViewerReady: false,
                              isShowDimensions: false,
                              isShowAnnotations: false,
                              isARModalOpen: false,
                            });
                          }}
                        >
                          <img
                            src={variant.thumbnailUrl}
                            className="mb-2 bg-secondary"
                            width="100%"
                            style={{ maxHeight: '168px', objectFit: 'contain' }}
                          />

                          <h6>{variant.name}</h6>

                          <p style={{ color: '#666666' }}>
                            {variant.shortDescription}
                          </p>
                        </ToggleButton>
                      </Col>
                    ),
                  )}
                </Row>

                <HorizontalScrollContainer className="d-md-none mb-3">
                  {this.props.location.state.product.variants.map(
                    (variant: any) => (
                      <ToggleButton
                        color="primary"
                        className="text-black text-start border-secondary me-2"
                        style={{ height: '112px' }}
                        active={this.state.activeVariant == variant}
                        onClick={() => {
                          this.setState({
                            activeVariant: variant,
                          });
                        }}
                      >
                        <img
                          src={variant.thumbnailUrl}
                          className="mb-2"
                          width="98px"
                          height="68px"
                        />

                        <h6>{variant.name}</h6>
                      </ToggleButton>
                    ),
                  )}
                </HorizontalScrollContainer>
              </>
            )}

            <Button
              color="primary"
              className="text-uppercase w-100 mt-auto"
              onClick={() => {
                this.props.history.push({
                  pathname: `/summary`,
                  state: {
                    product: this.props.location.state.product,
                    variant: this.state.activeVariant,
                  },
                });
              }}
            >
              {'> Continue'}
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Configurator);
